import styled from 'styled-components';

export const BioSection = styled.section`
  padding: 32px 0;

  @media (min-width: 992px) {
    padding: 48px 0;    
  }

  p {
    margin-top: 16px;
    color: ${({ theme }) => theme.colors.sapphireBlue};
  }

  p:first-child {
    @media (min-width: 992px) {
      margin-top: 0;  
    }
  }
`;

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.colors.sapphireBlue};
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;