import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from "../components/seo"
import parse from 'html-react-parser'
import Img from "gatsby-image"

import { Container, Row, Col } from 'react-bootstrap';

import { BioSection, StyledH1 } from '../styles/bioPageStyles';

const BioPage = ( { data } ) => {
  const { fluid: bioPic, title: bioPicTitle } = data.contentfulBioPage.bioProfilePicture;
  const biography = data.contentfulBioPage.biography.childMarkdownRemark.html;

  return (
    <Layout>
      <SEO title="Bio Page" />
      <BioSection>
        <Container>
          <Row>
            <Col xs={12}>
              <StyledH1>Bio</StyledH1>
            </Col>
            <Col xs={12} lg={5}>
              <Img fluid={bioPic} alt={bioPicTitle} />
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 1 }}>
              {parse(biography)}
            </Col>
          </Row>
        </Container>
      </BioSection>
    </Layout>
  );
}

export default BioPage;

export const pageQuery = graphql`
  query bioPageQuery {
    contentfulBioPage {
      bioProfilePicture {
        title
        fluid(maxWidth: 600, quality: 80) {
          srcSet
          src
          sizes
          base64
          aspectRatio
        }
      }
      biography {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
